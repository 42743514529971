/**
 * Organisms / CMS plugins / Slider / Plain
 */

import Splide from '@splidejs/splide';

window.addEventListener('lotho:init', () => {
  const initialize = function() {
    document.querySelectorAll('.gw-c-slider').forEach((slider) => {
      const slideCount = slider.querySelectorAll('li.splide__slide').length;
      const splide = new Splide(slider, {
        type: 'loop',  // The type of the carousel. 'slide' | 'loop' | 'fade'.
        arrows: true,  // Determines whether to create/find arrows or not.
        gap: 0,  // The gap between slides. The CSS format is acceptable.
        pagination: false,  // Determines whether to create pagination (indicator dots) or not.
        easing: 'linear',  // The timing function for the CSS transition.
        drag: slideCount > 1,  // Determines whether to allow the user to drag the carousel or not.
        // snap: false,  // Snaps the closest slide in the drag free mode.
        autoplay: false,  // Determines whether to activate autoplay or not.
        // interval: 3000,  // The autoplay interval duration in milliseconds. Use the data-splide-interval attribute to override this value for the specific slide.
        speed: 500,
        // pauseOnHover: true  // Determines whether to pause autoplay on mouseover. This should be true for accessibility.
        perPage: 1,  // Determines the number of slides to display in a page.
        perMove: 1,  // Determines the number of slides to move at once.
        // fixedWidth: '100%',  // Fixes width of slides, accepting the CSS format. The carousel will ignore the perPage option if you provide this value.
        mediaQuery: 'min',  // If 'min', the media query for breakpoints will be min-width, or otherwise max-width.
        breakpoints: {
          768: {
            perPage: slideCount > 1 ? 2 : slideCount,
            drag: slideCount > 2,
            // autoplay: slideCount > 2,  // readonly
          },
          1024: {
            perPage: slideCount > 2 ? 3 : slideCount,
            drag: slideCount > 3,
            // autoplay: slideCount > 3,  // readonly
          },
        },
      });

      splide.mount();
      slider.classList.add('gw-m-js');

      slider.splide = splide;
    });
  };

  const destroy = function() {
    document.querySelectorAll('.gw-c-slider').forEach((slider) => {
      slider.classList.remove('gw-m-js');
      slider.splide?.destroy();
    });
  };

  window.LOTHO.initializers.slider = initialize;
  window.LOTHO.destroyers.slider = destroy;

  initialize();
});

/**
 * Organisms / Accordion / POI
 */

import { debounce } from '../../../../src/scripts/utils';

window.addEventListener('accordion:init', (event) => {
  const accordion = event.target;
  const mediaQuery = window.matchMedia('(min-width: 1024px)');

  function openFirst(accordion) {
    if (!accordion.dirty) {
      const item = accordion.items[0];
      if (mediaQuery.matches) {  // medium
        item?.open();
        accordion.dirty = false;
      } else {
        item?.close();
      }
    }
  }

  if (accordion.classList.contains('gw-m-poi')) {
    accordion.dirty = false;
    accordion.addEventListener('accordion:item-open', () => accordion.dirty = true);

    openFirst(accordion);
    window.addEventListener('resize', debounce(() => openFirst(accordion)));
  }
});

/**
 * Molecules / Map
 */

import { debounce } from '../../../src/scripts/utils';

window.addEventListener('DOMContentLoaded', function() {
  const mapSelector = '.gw-c-map',
        defaultMarkerColor = 'var(--gw-global--palette-waikawa)';


  /**
   * Factory function for map-related events
   *
   * @param type - event type
   * @param data - additional event data
   * @return a `CustomEvent` instance
   */
  function eventFactory(type, data={ }) {
    return new CustomEvent(type, {
      bubbles: true,
      detail: data
    })
  }


  /**
   * Build a Leaflet tile layer using a map from opentopomap
   *
   * @return a Leaflet tile layer
   */
  function openTopoMap() {
    return L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
      maxZoom: 16
    })
  }

  /**
   * Build a marker icon
   *
   * @param spriteName - spritesheet name
   * @param identifier - identifier for the marker symbol in the spritesheet
   * @return a DOM node for the marker icon
   */
  function markerIconFactory(spriteName, identifier, color=defaultMarkerColor) {
    const node = document
      .importNode(document.getElementById('map-marker').content, true)
      .firstElementChild;
    const symbolInstance = node.querySelector('use');
    const symbolURL = symbolInstance
      .getAttribute('href')
      .replace(/[^\/]+\.svg/, `${spriteName}.svg`)
      .replace(/\#.*$/, `#${identifier}`);

    symbolInstance.setAttribute('href', symbolURL);
    symbolInstance.setAttribute('xlink:href', symbolURL);
    node.setAttribute('style', `color: ${color}`);

    return node;
  }


  /**
   * Build a marker for a point
   *
   * @param location - point coordinates
   * @param name - point name
   * @param spriteName - marker icon spritesheet name
   * @param iconId - marker icon identifier
   * @param color - marker color
   * @return a marker instance
   */
  function markerFactory(location, name, spriteName, iconId, color=defaultMarkerColor) {
    return L.marker(location, {
      title: name,
      draggable: false,
      icon: L.divIcon({
        html: markerIconFactory(spriteName, iconId, color),
        iconSize: [19, 26],
        iconAnchor: [9, 26],
        popupAnchor: [0, -22]
      })
    });
  }


  /**
   * Build a popup for a point of interest
   *
   * @param name - point name
   * @param image - URL of an image
   * @param url - point of interest URL
   * @return a DOM node for the new popup
   */
  function poiPopupFactory(name, image, url) {
    const node = document
      .importNode(document.getElementById('map-popup-place').content, true)
      .firstElementChild;
        const heading = node.querySelector('header').firstElementChild,
          imageNode = node.querySelector('img'),
          linkNode = node.querySelector('a');


    heading.innerHTML = name;
    imageNode.setAttribute('alt', name);
    imageNode.setAttribute('src', image);
    linkNode.setAttribute('href', url);

    return node;
  }


  /**
   * Build a popup for a track
   *
   * @param name - track name
   * @param activity - track activity descriptor
   * @param length - track length
   * @param drop - track drop
   * @param url - track url
   * @return a DOM node for the new popup
   */
  function trackPopupFactory(name, activity, length, drop, url) {
    const node = document
      .importNode(document.getElementById('map-popup-track').content, true)
      .firstElementChild;
    const heading = node.firstElementChild.firstElementChild,
          label = heading.nextElementSibling,
          trackLength = node.querySelector('dl > div:first-child span'),
          trackDrop = node.querySelector('dl > div:last-child span'),
          trackUrl = node.querySelector('a');

    heading.innerHTML = name;
    label.innerHTML = activity.name;
    label.setAttribute('style', `--gw-c-route-map-card__activity--Color: ${activity.color}`);
    trackLength.innerHTML = Math.round(length);
    trackDrop.innerHTML = drop;
    trackUrl.setAttribute('href', url);

    return node
  }


  /**
   * Add some city overlays to a map
   *
   * @param node - DOM node for the map
   * @param map - map instance
   * @param cities - cities data
   * @return a dictionary with the new overlays
   */
  function addCities(node, map, cities) {
    const overlays = { }
    const clusterGroup = new L.markerClusterGroup().addTo(map);

    cities.forEach(city => {
      const overlay = new L.FeatureGroup();

      city.tracks.forEach(track => {
        const popup = trackPopupFactory(
          track.name,
          track.activity,
          track.length,
          track.drop,
          track.url
        );
        const marker = markerFactory(
          track.location,       // location
          track.name,           // name
          'ui',                 // spriteName
          'marker--track',      // iconId
          track.activity.color  //color
        ).bindPopup(popup, { maxWidth: 200 }).addTo(overlay);

        node.dispatchEvent(eventFactory('map:marker', { marker: marker }));
      });
      overlay.addTo(clusterGroup);
      overlays[city.id] = overlay;
      node.dispatchEvent(eventFactory(
        'map:city', { city: city.name, overlay: overlay, map: map }
      ));
    });

    return overlays;

  }


  /**
   * Add some POI category overlays to a map
   *
   * @param node - DOM node for the map
   * @param map - map instance
   * @param categories - categories data
   * @return the created overlays
   */
  function addCategories(node, map, categories) {
    const overlays = { }

    categories.forEach(category => {
      if (category.points && category.points.length) {
        const overlay = new L.FeatureGroup();
        const icon = markerIconFactory(
          'poi',                    // spriteName
          `${category.id}--marker`  // iconId
        );
        const label = `${icon.outerHTML} ${category.name}`;

        category.points.forEach(poi => {
          const popup = poiPopupFactory(poi.name, poi.image, poi.url);
          const marker = markerFactory(
            poi.location,             // location
            poi.name,                 // name
            'poi',                    // spriteName
            `${category.id}--marker`  // iconId
          ).bindPopup(popup, { minWidth: 190 }).addTo(overlay);

          node.dispatchEvent(eventFactory('map:marker', { marker: marker }));
        });
        overlays[label] = overlay;
        node.dispatchEvent(eventFactory(
          'map:category',
          { category: category.name, overlay: overlay, map: map }
        ));
      }
    });

    return overlays;
  }


  /**
   * Add some miscellaneous point to a map
   *
   * @param node - DOM node for the map
   * @param map - map instance
   * @param pointData - point data array
   */
  function addPoints(node, map, pointData) {
    var overlay = new L.FeatureGroup().addTo(map);
    pointData.forEach(point => {
      const marker = markerFactory(
        point.location,                   // location
        point.name,                       // name
        'ui',                             // spriteName
        'marker--track',                  // iconId
        'var(--gw-global--palette-red)',  // color
      ).addTo(overlay).bindPopup(point.name, {
        autoclose: false,
        closeButton: false,
        closeOnClick: false,
        closeOnEscapeKey: false,
      }).openPopup();
    });
    return overlay;
  }

  /**
   * Add contents to an already init map
   *
   * @param node - DOM node for the map
   * @param map - map instance
   * @param config - map configuration
   */
  function addMapData(node, map, config) {
    let dataNode = null,
        categoryLayers = { },
        cityLayers = { };

    // track points
    dataNode = node.querySelector('.cities');
    if (dataNode) {
      cityLayers = addCities(node, map, JSON.parse(dataNode.innerHTML))
    }

    // POI categories
    dataNode = node.querySelector('.categories');
    if (dataNode) {
      const categories = JSON.parse(dataNode.innerHTML);

      if (categories && categories.length) {
        categoryLayers = addCategories(node, map, JSON.parse(dataNode.innerHTML));

        if (config.showOverlays && categoryLayers !== { }) {
          const layersControl = L.control.layers({ }, categoryLayers, {
            collapsed: map.getSize().x < 1008
          });

          layersControl.addTo(map);

          const resizeHandler = (event) => {
            // the map is full width
            if (event.oldSize < event.newSize && event.newSize.x > 1008) {
              layersControl.expand();
            } else if (event.oldSize > event.newSize && event.newSize.x < 1008) {
              layersControl.collapse();
            }
          };

          map.on('resize', debounce(resizeHandler));

          window.addEventListener('orientationchange', () => {
            const after = function() {
              if (window.innerWidth > 1008) {
                layersControl.expand();
              } else {
                layersControl.collapse();
              }
              window.removeEventListener('resize', debounce(after));
            }
            window.addEventListener('resize', debounce(after));
          });
        }
      }
    }

    // track
    dataNode = node.querySelector('.trip');
    if (dataNode) {
      const trip = JSON.parse(dataNode.innerHTML);
      const overlay = new L.FeatureGroup();  // this exists just to calculate bounds
      const controlElevation = L.control.elevation({
        theme: 'greenway-theme',
        useHeightIndicator: true,
        detached: false,
        position: 'bottomleft',
        summary: false,
        imperial: false,
        collapsed: true,
        autohide: false,
        legend: false,
        autofitBounds: false,  // this would only fit the first leg
      });

      trip.forEach(track => {
        const layer = new L.geoJSON(JSON.parse(track.data)).addTo(overlay);
        const json = `{
          "name": "a",
          "type": "FeatureCollection",
          "features": [
            {
              "type": "Feature",
              "geometry": ${track.data},
              "properties": null
            }
          ]
        }`;

        controlElevation.load(json);
        if (track.start) {
          const marker = markerFactory(
            track.start,      // location
            track.name,       // name
            'ui',             // spriteName
            'marker--track',  // iconId
            track.color       // color
          ).addTo(map);
        }
      });

      map.flyToBounds(overlay.getBounds(), {
        maxZoom: 13,
        animate: true,
        duration: 1,
      });

      controlElevation.addTo(map);
    }

    // contact points
    dataNode = node.querySelector('.points');
    if (dataNode) {
      addPoints(node, map, JSON.parse(dataNode.innerHTML))
    }

    node.dispatchEvent(eventFactory('map:load', {
      map: map,
      categories: categoryLayers,
      cities: cityLayers,
    }));
  }


  /**
   * Init a DOM node as an interactive maps, then attach the map instance to
   * it as an expando property
   *
   * @param node - the DOM node to use as a map
   */
  function initMap(node) {
    const config = JSON.parse(node.querySelector('.config').innerHTML);
    const map = L.map(node, {
      center: config.center,
      zoom: config.zoom,
      // zoomControl: true,
      // scrollWheelZoom: false,
      minZoom: 10,
      maxZoom: 15,
      // touchZoom: true,
      // tap: false,
      gestureHandling: true
    });

    openTopoMap().addTo(map);

    if (config.locateUser) {
      L.control.locate({
        position: 'topleft',
        flyTo: true,
        keepCurrentZoomLevel: false,
        strings: {
          // FIXME(geobaldi): this needs to be translated
          title: 'Mostra la mia posizione',
        },
        showCompass: true,
        locateOptions: {
          // see https://leafletjs.com/reference-1.5.1.html#locate-options
          enableHighAccuracy: true,
          watch: true,
        },
      }).addTo(map);
    }

    node.map = {
      options: config,
      instance: map,
    }

    node.dispatchEvent(eventFactory(
      'map:init',
      { map: map, options: config })
    );
  }


  document.querySelectorAll(mapSelector).forEach((node) => {
    node.addEventListener('map:init', (event) => addMapData(
      event.target, event.detail.map, event.detail.options
    ));
    initMap(node);
  });

});

/**
 * Organisms / CMS plugins / Activity CTAs
 */

window.addEventListener('lotho:init', () => {
  const observerOptions = {
    root: document.getElementById('gw-c-page__wrapper'),  // could be null
    rootMargin: '0% 0% 0% 0%',
    threshold: 1,  // 100%
  };
  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.target.plugin) {
        entry.target.plugin.classList.add('gw-m-visible');
        observer.unobserve(entry.target);
      }
    });
  };
  const observer = new IntersectionObserver(observerCallback, observerOptions);

  const initialize = function() {
    document.querySelectorAll('.gw-c-activity-ctas').forEach((plugin) => {
      const witness = plugin.querySelector(':scope > nav');

      plugin.classList.add('gw-m-js');
      if (witness) {
        witness.plugin = plugin;
        observer.observe(witness);
      }
    });
  };

  const destroy = function() {
    document.querySelectorAll('.gw-c-activity-ctas').forEach((plugin) => {
      const witness = plugin.querySelector(':scope > nav');

      plugin.classList.remove('gw-m-js');
      if (witness) {
        witness.plugin = undefined;
        observer.unobserve(witness);
      }
    });
  };

  window.LOTHO.initializers.activityCtas = initialize;
  window.LOTHO.destroyers.activityCtas = destroy;

  initialize();

});

/**
 * Organisms / Plugins / Video
 */

import A11yDialog from 'a11y-dialog';


window.addEventListener('lotho:init', () => {
  const iframeApi = document.createElement('script');

  iframeApi.src = 'https://www.youtube.com/iframe_api';

  document.querySelector('body').appendChild(iframeApi);

  window.onYouTubeIframeAPIReady = () => {
    window.dispatchEvent(new Event('youtube:ready'));
  };

  const initialize = function() {
    document.querySelectorAll('.gw-c-video-player').forEach((player) => {
      player.classList.add('gw-m-js');

      player.openButton = player.querySelector(':scope > button');
      player.dialog = new A11yDialog(player.querySelector(':scope > .gw-c-video-player__dialog'));
      player.closeButton = player.querySelector(':scope > .gw-c-video-player__dialog > button');

      player.video = player.querySelector(':scope > .gw-c-video-player__dialog > video');

      player.iframe = player.querySelector(':scope > .gw-c-video-player__dialog > iframe');
      player.youtube = !! player?.iframe?.src?.match('youtube');

      if (player.youtube) {
        window.addEventListener('youtube:ready', (event) => {
          player.ytPlayer = new window.YT.Player(player.iframe);
        });
      }

      player.pause = () => {
        if (player.ytPlayer) {
          player.ytPlayer.pauseVideo();
        }
        if (player.video) {
          player.video.pause();
        }
      };

      player.play = () => {
        if (player.ytPlayer) {
          player.ytPlayer.playVideo();
        }
        if (player.video) {
          player.video.play();
        }
      }

      player.openButton.addEventListener('click', (event) => {
        player.dialog.show();
        player.dispatchEvent(new Event('dialog:open', { bubbles: true }));
        player.play();
      });

      player.closeButton.addEventListener('click', (event) => {
        player.pause();
        window.dispatchEvent(new Event('dialog:close', { bubbles: true }));
      });

    });
  };
  const destroy = function() {
    document.querySelectorAll('.gw-c-video-player').forEach((player) => {
      player.classList.remove('gw-m-js');
    });
  };

  window.LOTHO.initializers.videoPlayer = initialize;
  window.LOTHO.destroyers.videoPlayer = destroy;

  initialize();
});

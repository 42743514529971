/**
 * Organisms / Page header
 */

window.addEventListener('DOMContentLoaded', function() {
  const headers = document.querySelectorAll('.gw-c-page-header');

  headers.forEach((header) => {
    header.hide = function() {
      this.style.setProperty('--gw-c-page-header__status--hidden', '1');
    };

    header.show = function() {
      this.style.setProperty('--gw-c-page-header__status--hidden', '0');
    };
  });

  window.addEventListener('scrolldown', (event) => headers.forEach((header) => header.hide() ));
  window.addEventListener('scrollup', (event) => headers.forEach((header) => header.show() ));

  window.addEventListener('dialog:open', (event) => headers.forEach((header) => header.hide() ));
  window.addEventListener('dialog:close', (event) => headers.forEach((header) => header.show() ));

});

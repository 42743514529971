/**
 * Molecules / Score list
 */

window.addEventListener('DOMContentLoaded', function() {

  const observerOptions = {
    root: document.getElementById('gw-c-page__wrapper'),  // could be null
    rootMargin: '0% 0% 0% 0%',
    threshold: 1,  // 100%
  };

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('gw-m-visible');
        observer.unobserve(entry.target);
      }
    });
  };
  const observer = new IntersectionObserver(observerCallback, observerOptions);

  document.querySelectorAll('.gw-c-score-list').forEach((list) => {
    list.classList.add('gw-m-js');
    observer.observe(list);
  });

});

/**
 * Molecules / Card / Squared / Activity
 */

window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.gw-c-card-squared.gw-m-activity').forEach((card) => {
    // This is just a horrible hack to extend the card link clickable area.
    card.link = card.querySelector(':scope > a');
    card.addEventListener('click', () => card.link.click());
  });
});

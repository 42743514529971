/**
 * Organisms / Accordion / Base
 */

import { debounce, sleep } from '../../../../src/scripts/utils';

window.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('.gw-c-accordion').forEach((accordion) => {
    accordion.items = accordion.querySelectorAll(':scope > li');
    accordion.isDisabled = accordion.classList.contains('gw-m-disabled');

    accordion.items.forEach((item) => {
      item.toggle = item.querySelector(':scope > input');
      item.label = item.querySelector(':scope > article > header label');
      item.content = item.querySelector(':scope > article > div');
      item.parent = accordion;

      item.open = function() {
        if (!this.toggle.checked) {
          this.toggle.checked = true;
          this.dispatchEvent(new Event('accordion:item-open', {bubbles: true}));
        }
      };

      item.close = function() {
        if (this.toggle.checked) {
          this.toggle.checked = false;
          this.resize();
          this.dispatchEvent(new Event('accordion:item-close', {bubbles: true}));
        }
      };

      item.isVisible = function() {
        const handle = this.label.parentNode;
        const bounding = handle.getBoundingClientRect();
        return bounding.top > 0 && bounding.bottom > 0;
      };

      item.resize = function() {
        if (!item.parent.isDisabled) {
          const height = item.content.scrollHeight;
          item.style.removeProperty('--gw-c-accordion__content--Height');  // reflow
          if (height > 0) {
            item.style.setProperty(
              '--gw-c-accordion__content--Height',
              `${height}px`
            );
          }
        }
      };

      item.label.addEventListener('click', (event) => {
        item.resize();
        if (item.toggle.checked) {
          event.preventDefault();
          item.close();
        } else {
          item.parent.items.forEach((item) => item.close());
          item.open();
          sleep(300).then(() => {  // FIXME: magic number
            if (!item.isVisible()) {
              item.label.parentNode.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
              });
            }
          });
        }
      });

      item.resize();
    });

    accordion.dispatchEvent(new Event('accordion:init', { bubbles: true }));

    if (!accordion.isDisabled) {
      window.addEventListener(
        'resize',
        debounce((event) => accordion.items.forEach((item) => item.resize()))
      );
    }

  });

});

/**
 * Atoms / Forms / Widgets / Interval
 */

window.addEventListener('DOMContentLoaded', function() {

  /**
   * Convert a number of seconds to a string representation in the format
   * 'hh:mm'
   *
   * @param seconds - number of seconds
   * @return a string representation of `seconds`
   */
  function secondsToHours(seconds) {
      seconds = Number(seconds);

      const hours = Math.floor(seconds / 3600),
            minutes = Math.floor(seconds % 3600 / 60);

      return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);
  }

  /**
   * Update a slider output
   *
   * @param node - slider DOM node
   * @param formatter - output formatter
   */
  function updateOutput(node, formatter) {
    const value = node.value,
          outputNode = node.nextElementSibling;

    outputNode.value = eval(formatter);
  }

  document.querySelectorAll('.gw-c-widget-interval').forEach((node) => {
    const sliders = node.querySelectorAll('input');
    const formatter = node.getAttribute('data-formatter') || '`${value}`';

    sliders.forEach((slider) => updateOutput(slider, formatter));

    node.addEventListener('input', (event) => {
      const start = Number(sliders[0].value);
      const end = Number(sliders[1].value);

      if (start > end) {
        sliders[0].value = end;
        sliders[1].value = start;
      }

      sliders.forEach((slider) => updateOutput(slider, formatter));
    });
  });

});

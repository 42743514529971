/**
 * Organisms / Card holder / Activity
 */

window.addEventListener('DOMContentLoaded', function() {

  document.querySelectorAll('.gw-c-cardholder-activity').forEach((cardHolder) => {
    cardHolder.witness = cardHolder.querySelector('li:nth-of-type(1) > .gw-c-card-squared');

    cardHolder.update = function() {
      const witnessRect = this.witness.getBoundingClientRect();
      const totalDistance = window.innerHeight / 2 + witnessRect.height / 4;
      const currentDistance = witnessRect.top + witnessRect.height / 2 - window.innerHeight / 2;
      const factor = Math.min(Math.max(currentDistance / totalDistance, 0), 1);

      console.log(factor);

      this.style.setProperty('--gw-c-cardholder-activity__spacer--multiplier', factor);
    };

    cardHolder.update();

    window.addEventListener('scrollup', () => cardHolder.update());
    window.addEventListener('scrolldown', () => cardHolder.update());

  });
});

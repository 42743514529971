/**
 * Organisms / CMS plugins / Slider / Home page
 */

window.addEventListener('lotho:init', () => {
  function parseDuration(duration) {
    let multiplier = duration.indexOf('ms') !== -1 ? 1 : 1000;
    return parseInt(parseFloat(duration) * multiplier, 10);
  }

  const initialize = function() {
    document.querySelectorAll('.gw-c-home-slider').forEach((slider) => {
      slider.classList.add('gw-m-js');

      slider.timers = slider.querySelectorAll('.gw-c-home-slider__button');
      slider.slides = slider.querySelectorAll(':scope > ul > li');
      slider.selectedIndex = null;
      slider.intervalId = null;
      slider.animationDuration = parseDuration(window.getComputedStyle(slider).getPropertyValue('--gw-c-home-slider--AnimationDuration'));

      slider.slides.forEach((slide, index) => {
        slide.timer = slider.timers[index];
        slide.picture = slide.querySelector(':scope > figure > img');
      });

      slider.select = function(index) {
        index = index % this.slides.length;
        const slide = this.slides[index];
        if (slide.picture.complete) {
          this._select(index);
        } else {
          slide.picture.addEventListener(
            'load',
            () => {
              this._select(index);
            },
            { once: true },
          );
        }
      }

      slider._select = function(index) {
        const slide = this.slides[index];

        this.slides.forEach((slide) => {
          slide.classList.remove('gw-m-active');
          slide.timer.disabled = false;
        });

        slide.classList.add('gw-m-active');
        slide.timer.disabled = true;
        this.selectedIndex = index;
        this.restart();
      };

      slider.next = function() {
        const index = this.selectedIndex || 0;
        this.select(index + 1);
      };

      slider.previous = function() {
        const index = this.selectedIndex || 0;
        this.select(index - 1);
      };

      slider.stop = function() {
        clearInterval(this.intervalId);
      };

      slider.restart = function() {
        if (null === this.selectedIndex) {
          this.select(0);
        }
        this.stop();
        this.intervalId = setInterval(() => this.next(), this.animationDuration);
      };

      slider.restart();

      slider.querySelector('.gw-c-home-slider__next').addEventListener('click', () => slider.next());
      slider.timers.forEach((button) => {
        button.addEventListener('click', () => slider.select(button.dataset.index));
      });

    });
  };

  const destroy = function() {
    document.querySelectorAll('.gw-c-home-slider').forEach((slider) => {
      slider.classList.remove('gw-m-js');

      if (slider.slides) {
        slider.slides.forEach((slide) => {
          slide.timer = undefined;
        });
      }

      slider.timers = undefined;
      slider.slides = undefined;
      slider.selectedIndex = undefined;
      slider.intervalId = undefined;
      slider.select = undefined;
      slider.next = undefined;
      slider.previous = undefined;
      slider.stop = undefined;
      slider.restart = undefined;
    });
  };

  window.LOTHO.initializers.homeSlider = initialize;
  window.LOTHO.destroyers.homeSlider = destroy;

  initialize();
});

/**
 * Organisms / CMS plugins / Directions
 */

import { debounce, sleep } from '../../../../src/scripts/utils';

window.addEventListener('accordion:init', (event) => {
  const accordion = event.target;

  if (accordion.classList.contains('gw-m-directions')) {
    let item = accordion.items[0];
    if (window.location.hash) {
      item = accordion.querySelector(`:scope > ${window.location.hash}`);
    }
    item?.open();
  }
});

/**
 * Organisms / CMS plugins / Latest blog posts
*/

window.addEventListener('lotho:init', () => {
  const initialize = function() {
    document.querySelectorAll('.gw-c-latest-posts').forEach((plugin) => {

      const pictures = Array.from(plugin.querySelectorAll('.gw-c-latest-posts__image > img:nth-of-type(n+2)'));

      plugin.querySelectorAll('section > ul > li.gw-m-image .gw-c-blog-list-item').forEach((item, index) => {
        item.addEventListener('mouseenter', (event) => {
          pictures[index].style.setProperty('--gw-c-latest-posts__image--multiplier', 1);
        });

        item.addEventListener('mouseleave', (event) => {
          pictures[index].style.setProperty('--gw-c-latest-posts__image--multiplier', 0);
        });
      });

    });
  };

  window.LOTHO.initializers.latestPosts = initialize;

  initialize();
});
